/**
 * SNS シェアボタン
 */

import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterShareButton,
  XIcon,
} from "react-share"

const ShareButtons = ({ title, size }) => {
  const [url, setUrl] = React.useState("")
  React.useEffect(() => {
    setUrl(window.location.href)
  }, [])

  if (!url) {
    return null
  }

  return (
    <div className="flex-row-top">
      <div className="mb-2 pr-2">
        <FacebookShareButton url={url} quote={title}>
          <FacebookIcon size={size} round={true} />
        </FacebookShareButton>
      </div>
      <div className="pr-2">
        <TwitterShareButton url={url} title={title}>
          <XIcon size={size} round={true} />
        </TwitterShareButton>
      </div>
      <div className="pr-2">
        <LineShareButton url={url} title={title}>
          <LineIcon size={size} round={true} />
        </LineShareButton>
      </div>
    </div>
  )
}

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

export default ShareButtons
