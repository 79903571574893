/**
 * 投稿のメタ情報
 */

import React from "react"
import PropTypes from "prop-types"

const PostMeta = ({ date }) => {
  return (
    <div className="post-meta">
      <time className="post-meta-date">{date}</time>
    </div>
  )
}

PostMeta.propTypes = {
  date: PropTypes.string.isRequired,
}

export default PostMeta
