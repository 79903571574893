import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import Markdown from "../components/markdown"
import PostMeta from "../components/post-meta"
import ShareButtons from "../components/share-buttons"

const PostDetailPage = ({ data: { strapiPost: { fields } }, location }) => {
  const { title, date, areas, description, keywords, ogpImage } = fields
  const parents = [{
    path: `/articles/`,
    label: `記事一覧`,
  }]

  return (
    <Layout>
      <Seo title={title} description={description} keywords={keywords} image={ogpImage} />
      <Breadcrumbs parents={parents} leaf={title} />
      <h1>{title}</h1>
      <div className="l-pc_tablet-flex-row-between">
        <PostMeta date={date} areas={areas} />
        <ShareButtons title={title} size={36} />
      </div>
      <div className="post-body">
        <Markdown>{fields.body}</Markdown>
      </div>
      <div className="post-area">
        {
          areas ?
            areas.map(area => (
              <Link key={area.machineName} to={area.path} className="pr-2 post-area-item">{area.name}</Link>
            ))
            : ``
        }
      </div>
    </Layout>
  )
}

export default PostDetailPage

export const query = graphql`
  query PostDetailQuery($id: String!) {
    strapiPost(id: { eq: $id }) {
      fields {
        title
        body
        date(formatString: "YYYY.MM.DD")
        areas {
          name
          machineName
          path
        }
        description
        keywords
        ogpImage
      }
    }
  }
`
